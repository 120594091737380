import React from "react";
import { Link } from "react-router-dom";

// BASE COMPONENTS
import GridContainer from "components/grid-container/GridContainer.component";
import GridItem from "components/grid-item/GridItem.component";
import Input from "components/input/Input.component";
import Form from "components/form/Form.component";
import Button from "components/button/Button.component";
// EFFECTS
import useInput from "effects/useInput.effect";
// IMAGES
import logo from "assets/images/starter-screen/preloader-logo.svg";

const LoginPage = () => {
  const {
    inputState,
    handleInput,
    handleInvalidMessage,
    invalidMessages,
  } = useInput();

  const handleInputChange = (event) => {
    handleInput(event);
  };

  const onSubmit = (event) => {
    event.preventDefault();

    console.log(inputState);
  };

  return (
    <section className="login-page">
      <GridItem xs={12} sm={12} md={12} lg={12}>
        <Form onSubmit={onSubmit} className="login-page__form" heading="Login">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Input
                name="username"
                value={inputState.username}
                error={invalidMessages}
                onChange={handleInputChange}
                onInvalid={handleInvalidMessage}
                autoComplete="on"
                type="text"
                placeholder="User name"
                required
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Input
                name="password"
                value={inputState.password}
                error={invalidMessages}
                onChange={handleInputChange}
                onInvalid={handleInvalidMessage}
                type="text"
                placeholder="Password"
                required
              />
            </GridItem>
          </GridContainer>
          <GridContainer className="fy-flex__center">
            <GridItem className="fy-pb__1" xs={12} sm={12} md={12} lg={12}>
              <Link
                className="login-page__form_forgot-link"
                to="/reset-password">
                Forgot your password?
              </Link>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Link className="login-page__form_forgot-link" to="/register">
                Register
              </Link>
            </GridItem>
          </GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Button className="login-page__form_button">Login</Button>
          </GridItem>
          <GridContainer className="fy-pb__3">
            <GridItem className="fy-pb__" xs={12} sm={12} md={12} lg={12}>
              <img
                className="login-page__form_footer-logo"
                src={logo}
                alt="logo"
              />
            </GridItem>
          </GridContainer>
          <div className="fy-bottom__10">
            <p className="login-page__form_terms-text">
              By proceeding, I accept the Terms of Use and have read and
              understand the Privacy Policy.
            </p>
          </div>
        </Form>
      </GridItem>
    </section>
  );
};

export default LoginPage;
