import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  // CATEGORIES
  categories: [],
  isCategoriesLoading: false,
  categoriesErrorMessage: "",
  // GLOBAL MESSAGE
  globalMessage: null,
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    // CATEGORIES REDUCER
    getCategoriesStart: (state) => {
      state.isCategoriesLoading = true;
    },
    getCategoriesSuccess: (state, { payload }) => {
      state.isCategoriesLoading = false;
      state.categories = payload;
      state.categoriesErrorMessage = "";
    },
    getCategoriesFailure: (state, { payload }) => {
      state.categoriesErrorMessage = payload;
      state.isCategoriesLoading = false;
      state.categories = [];
    },
    // GLOBAL MESSAGE REDUCER
    setGlobalMessage: (state, { payload }) => {
      state.globalMessage = payload;
    },
  },
});

// Extract the action creators object and the reducer
const { actions } = commonSlice;
// Extract and export each action creator by name
export const {
  getCategoriesStart,
  getCategoriesSuccess,
  getCategoriesFailure,
  setGlobalMessage,
} = actions;
// Export the reducer, either as a default or named export
export default commonSlice;
export const selectCommon = (state) => state.common;
