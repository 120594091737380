import React from "react";

// BASE COMPONENTS
import GridContainer from "components/grid-container/GridContainer.component";
import GridItem from "components/grid-item/GridItem.component";
import Tab from "components/tab/Tab.component";
// IMAGES
import logo from "assets/images/starter-screen/preloader-logo.svg";
// TABS
import UserNameTab from "pages/auth/register/tabs/username/UserName.tab";
import AboutTab from "pages/auth/register/tabs/about/About.tab";
import HashtagsTab from "pages/auth/register/tabs/hashtags/Hashtags.tab";

const FacebookRegisterPage = () => {
  const tabLabels = [
    { id: 1, label: "Step 1" },
    { id: 2, label: "Step 2" },
    { id: 3, label: "Step 3" },
  ];

  const tabPanels = [
    {
      id: 1,
      item: <UserNameTab />,
      heading: `Tell us about you \n(Tell us your name and choose a user name for you)`,
    },
    {
      id: 2,
      item: <AboutTab />,
      heading: `Tell us more! \n(In order to have a better poll which suits you more!)`,
    },
    {
      id: 3,
      item: <HashtagsTab />,
      heading: `What are you interested for? \n \n(The more categories you mention, the better poll you will have)`,
    },
  ];

  return (
    <section className="fb-register">
      <GridContainer className="fb-register__tab-block">
        <h4>Sign up with Facebook</h4>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Tab tabLabels={tabLabels} tabPanels={tabPanels} />
        </GridItem>
      </GridContainer>
      <div className="fb-register__footer">
        <img src={logo} alt="footer-logo" />
      </div>
    </section>
  );
};

export default FacebookRegisterPage;
