import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// AUTH ROUTE PAGES
import LoginPage from "./login/Login.page.jsx";
import PasswordResetPage from "./password-reset/PasswordReset.page";
import RegisterPage from "./register/Register.page";
import FacebookRegisterPage from "./register/screens/facebook-register/FacebookRegister.screen";
import EmailRegisterPage from "./register/screens/email-register/EmailRegister.screen";
// LANDING PAGE /* should be removed after connecting to API */
import LandingPage from "../landing/Landing.page";
// LAYOUTS
import Page from "layouts/page-wrapper/PageWrapper.layout";

const AuthPages = () => {
  return (
    <div className="auth-pages">
      <Switch>
        <Route exact path="/login">
          <Page title="Login Page">
            <LoginPage />
          </Page>
        </Route>
        <Route exact path="/register">
          <Page title="Register Page">
            <RegisterPage />
          </Page>
        </Route>
        <Route exact path="/register/facebook">
          <Page title="Register Page [Facebook]">
            <FacebookRegisterPage />
          </Page>
        </Route>
        <Route exact path="/register/email">
          <Page title="Register Page [Email]">
            <EmailRegisterPage />
          </Page>
        </Route>
        <Route exact path="/reset-password">
          <Page title="Reset Password Page">
            <PasswordResetPage />
          </Page>
        </Route>
        <Route exact path="/landing">
          <Page title="Landing Page">
            <LandingPage />
          </Page>
        </Route>
        <Redirect to="/login" />
      </Switch>
    </div>
  );
};

export default AuthPages;
