import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  token: "",
  isLoading: false,
  error: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logOut: (state) => {
      window.localStorage.clear();
      window.sessionStorage.clear();
      return { ...initialState };
    },
  },
});

// Extract the action creators object and the reducer
const { actions } = authSlice;
// Extract and export each action creator by name
export const { logOut } = actions;
// Export the reducer, either as a default or named export
export default authSlice;
