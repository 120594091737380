import storage from "redux-persist/lib/storage";
// import sessionStorage from "redux-persist/lib/storage/session";

// ROOT PERSIST CONFIG
export const persistConfig = {
  key: "root",
  storage: storage,
};

// AUTH PERSIST CONFIG
export const authPersistConfig = {
  key: "auth",
  storage: storage,
};
