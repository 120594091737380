// REACT DOM
import React from "react";
import { hydrate, render } from "react-dom";
// REACT ROUTER DOM
import { BrowserRouter } from "react-router-dom";
// REDUX
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
// SERVICE WORKERS
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
// MAIN APP COMPONENT
import App from "app/App";
// MAIN CSS
import "./index.scss";
// STORE
import store, { persistor } from "./redux/store";

const rootElement = document.getElementById("root");

// USING REACT-SNAP FOR SEO, SERVER SIDE RENDERING, ETC
if (rootElement.hasChildNodes()) {
  hydrate(
    <Provider store={store}>
      <BrowserRouter>
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
      </BrowserRouter>
    </Provider>,
    rootElement
  );
} else {
  render(
    <Provider store={store}>
      <BrowserRouter>
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
      </BrowserRouter>
    </Provider>,
    rootElement
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
