import React from "react";
import classNames from "classnames";

// BOOTSTRAP COMPONENTS
// import BootstrapButton from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

const Button = (props) => {
  const { children, className, isLoading, disabled, ...rest } = props;

  const btnClasses = classNames({
    "fy-button": true,
    [className]: className,
  });

  return (
    <button className={btnClasses} {...rest} disabled={disabled || isLoading}>
      <span>{children}</span>
      {isLoading && (
        <div className="spinner">
          <Spinner animation="grow" variant="light" />
        </div>
      )}
    </button>
  );
};

export default Button;
