import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// BASE COMPONENTS
import GridItem from "components/grid-item/GridItem.component";
import Form from "components/form/Form.component";
import Button from "components/button/Button.component";
import LoaderWrapper from "components/loader-wrapper/LoaderWrapper.component";
// ACTIONS
import { getCategoriesAsync } from "redux/common/common.actions";
// EFFECTS
import useInput from "effects/useInput.effect";
// SELECTORS
import { selectCommon } from "redux/common/common.slice";

const HashtagsTab = () => {
  const {
    inputState,
    // handleInput,
    // handleInvalidMessage,
    // invalidMessages,
  } = useInput();

  const dispatch = useDispatch();
  const commonStore = useSelector(selectCommon);

  const { categories, isCategoriesLoading } = commonStore;

  useEffect(() => {
    if (categories.length <= 0) {
      dispatch(getCategoriesAsync());
    }
    // eslint-disable-next-line
  }, [])


  // const handleInputChange = (event) => {
  //   handleInput(event);
  // };

  const onSubmit = (event) => {
    event.preventDefault();

    console.log(inputState);
  };

  return (
    <div className="hashtags-tab">
      <Form onSubmit={onSubmit} className="hashtags-tab__form">
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <LoaderWrapper isLoading={isCategoriesLoading}>
            <div className="hashtags-block">
              {categories.map((category) => {
                return (
                  <span className="hashtags-block__item" key={category?.id}>
                    {category?.name}
                  </span>
                );
              })}
            </div>
          </LoaderWrapper>
        </GridItem>
        <GridItem className="fy-flex__center" xs={12} sm={12} md={12} lg={12}>
          <Button className="username-tab__form_button">Register</Button>
        </GridItem>
      </Form>
    </div>
  );
};

export default HashtagsTab;
