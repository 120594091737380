import React from "react";

// EFFECTS
import useDocumentTitle from "effects/useTitle.effect";

function Page({ title, children, keywoards, description }) {
  const titlePrefix = "Faverity | ";

  useDocumentTitle(`${titlePrefix}${title}`);

  const pageDescription = description || "Faverity social app";
  const pageKeywords = keywoards || "Social App, social, Faverity";

  return (
    <>
      <meta name="description" content={pageDescription} />
      <meta name="keywords" content={pageKeywords} />
      {children}
    </>
  );
}

export default Page;
