import React from "react";
import { useRouteMatch, useHistory } from "react-router-dom";

// BASE COMPONENTS
import GridContainer from "components/grid-container/GridContainer.component";
import GridItem from "components/grid-item/GridItem.component";
// COMPONENTS
import RegisterButton from "./components/register-button/RegisterButton.component";
// IMAGES
import logo from "assets/images/starter-screen/preloader-logo.svg";
import fbIcon from "assets/images/auth/fb-icon.png";
import emailIcon from "assets/images/auth/email-icon.png";

const RegisterPage = () => {
  const { url } = useRouteMatch();
  const history = useHistory();

  return (
    <section className="register-page">
      <GridItem xs={12} sm={12} md={12} lg={12}>
        <GridContainer className="fy-flex__center">
          <img className="register-page__logo" src={logo} alt="logo" />
        </GridContainer>
        <GridContainer className="fy-flex__center">
          <h3>Register</h3>
          <GridItem xs={9} sm={9} md={9} lg={9}>
            <RegisterButton
              icon={fbIcon}
              text="Sign up with Facebook"
              className="bg-fb"
              onClick={() => history.push(`${url}/facebook`)}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <div className="register-page__separator">― or ―</div>
          </GridItem>
          <GridItem xs={9} sm={9} md={9} lg={9}>
            <RegisterButton
              icon={emailIcon}
              text="Sign up with E-mail"
              className="bg-email"
              onClick={() => history.push(`${url}/email`)}
            />
          </GridItem>
        </GridContainer>
        <div className="fy-bottom__10">
          <p className="register-page__terms-text">
            By proceeding, I accept the Terms of Use and have read and
            understand the Privacy Policy.
          </p>
        </div>
      </GridItem>
    </section>
  );
};

export default RegisterPage;
