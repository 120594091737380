import commonApi from "./common.api";
import {
  getCategoriesStart,
  getCategoriesSuccess,
  getCategoriesFailure,
  setGlobalMessage,
} from "./common.slice";

export const getCategoriesAsync = () => async (dispatch) => {
  dispatch(getCategoriesStart());

  try {
    const data = await commonApi.getCategories();
    dispatch(getCategoriesSuccess(data));
  } catch (error) {
    dispatch(getCategoriesFailure(error.message));
    dispatch(
      setGlobalMessage({
        severity: "error",
        text: error.message,
      })
    );
  }
};
