import { API_URL } from "config/apiUrl.config";

const commonApi = {
  getCategories: async (options) => {
    let response = await fetch(`${API_URL}/categories`, options);
    return await response.json();
  },
};

export default commonApi;
