import React from "react";
import { useDispatch, useSelector } from "react-redux";

// BASE COMPONENTS
import Notification from "components/notification/Notification.component";
// ACTIONS, SELECTORS
import { setGlobalMessage, selectCommon } from "redux/common/common.slice";
// ROUTES
import FeedRoutes from "pages/feed/Feed.routes";
import AuthPages from "pages/auth/Auth.routes";
// APP STYLES
import "./App.scss";

function App() {
  const dispatch = useDispatch();
  const commonStore = useSelector(selectCommon);

  const { globalMessage } = commonStore;

  const isAuth = true;
  const View = isAuth ? <AuthPages /> : <FeedRoutes />;

  const handleNotificationClose = () => {
    dispatch(setGlobalMessage(null));
  };

  return (
    <main className="App">
      <Notification
        message={globalMessage?.text}
        isOpened={!!globalMessage}
        delay={7000}
        severity={globalMessage?.severity}
        handleNotificationClose={handleNotificationClose}
        position={{ vertical: "top", horizontal: "center" }}
      />
      {View}
    </main>
  );
}

export default App;
