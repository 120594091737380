import React from "react";
import classNames from "classnames";

// BASE COMPONENTS
import IsVisible from "components/is-visible/IsVisible.component";

const RegisterButton = ({ icon, text, className, ...rest }) => {
  const btnClasses = classNames({
    "register-button": true,
    [className]: className,
  });

  return (
    <div className={btnClasses} {...rest}>
      <IsVisible isVisible={icon}>
        <div className="register-button__icon">
          <img src={icon} alt={text} />
        </div>
      </IsVisible>
      <div className="register-button__text">{text}</div>
    </div>
  );
};

export default RegisterButton;
