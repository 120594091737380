import React from "react";
// REACT TABS
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
// BASE COMPONENTS
import IsVisible from "components/is-visible/IsVisible.component";

const TabComponent = ({ tabLabels, tabPanels }) => {
  return (
    <div className="tab">
      <Tabs>
        <TabList>
          {tabLabels.map((tab, idx) => (
            <Tab key={tab?.id || idx}>{tab.label}</Tab>
          ))}
        </TabList>
        <div className="tab__panels-container">
          {tabPanels.map((panel, idx) => (
            <TabPanel key={panel?.id || idx}>
              <IsVisible isVisible={panel?.heading}>
                <div className="tab__heading">{panel?.heading}</div>
              </IsVisible>
              {panel.item}
            </TabPanel>
          ))}
        </div>
      </Tabs>
    </div>
  );
};

export default TabComponent;
