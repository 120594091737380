import React from "react";

// BASE COMPONENTS
/*
 ********** COMPONENTS IMPORTING EXAMPLE ********
 // import Input from "views/input/Input.component";
 */
// COMPONENTS
import Header from "./views/header/Header.view";
import Content from "./views/content/Content.view";
import Footer from "./views/footer/Footer.view";

const LandingPage = () => {
  return (
    <section className="landing-page">
      <Header />
      <Content />
      <Footer />
    </section>
  );
};

export default LandingPage;
