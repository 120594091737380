import React from "react";

// BASE COMPONENTS
import GridItem from "components/grid-item/GridItem.component";
import Input from "components/input/Input.component";
import Button from "components/button/Button.component";
import Form from "components/form/Form.component";
import TextArea from "components/textarea/TextArea.component";
import RadioButtonsGroup from "components/radio-group/RadioGroup.component";
// EFFECTS
import useInput from "effects/useInput.effect";

const AboutTab = () => {
  const {
    inputState,
    handleInput,
    handleInvalidMessage,
    invalidMessages,
  } = useInput({ gender: "female" });

  const handleInputChange = (event) => {
    handleInput(event);
  };

  const onSubmit = (event) => {
    event.preventDefault();

    console.log(inputState);
  };

  const radioButtonsList = [
    {
      id: 1,
      value: "male",
      label: "Male",
    },
    {
      id: 2,
      value: "female",
      label: "Female",
    },
  ];

  return (
    <div className="about-tab">
      <Form onSubmit={onSubmit} className="username-tab__form">
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Input
            name="firstName"
            value={inputState.firstName}
            error={invalidMessages}
            onChange={handleInputChange}
            onInvalid={handleInvalidMessage}
            type="text"
            placeholder="First name"
            required
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Input
            name="lastName"
            value={inputState.lastName}
            error={invalidMessages}
            onChange={handleInputChange}
            onInvalid={handleInvalidMessage}
            type="text"
            placeholder="Last name"
            required
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          {/* shout be datepicker */}
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Input
            name="country"
            value={inputState.country}
            error={invalidMessages}
            onChange={handleInputChange}
            onInvalid={handleInvalidMessage}
            type="text"
            placeholder="Country"
            required
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <RadioButtonsGroup
            name="gender"
            formLabel="Gender"
            radioList={radioButtonsList}
            value={inputState.gender}
            onChange={handleInputChange}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Input
            name="instagramLink"
            value={inputState.instagramLink}
            error={invalidMessages}
            onChange={handleInputChange}
            onInvalid={handleInvalidMessage}
            type="text"
            placeholder="Instagram"
            required
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Input
            name="snapchatLink"
            value={inputState.snapchatLink}
            error={invalidMessages}
            onChange={handleInputChange}
            onInvalid={handleInvalidMessage}
            type="text"
            placeholder="Snapchat"
            required
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <TextArea
            name="aboutUs"
            value={inputState.aboutUs}
            error={invalidMessages}
            onChange={handleInputChange}
            onInvalid={handleInvalidMessage}
            placeholder="About us"
            minRows={2}
            required
          />
        </GridItem>
        <GridItem className="fy-flex__center" xs={12} sm={12} md={12} lg={12}>
          <Button className="username-tab__form_button">Next</Button>
        </GridItem>
      </Form>
    </div>
  );
};

export default AboutTab;
